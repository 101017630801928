<script>
// import Layout from "../../layouts/main";
import api from '../../../api'
/**
 * Dashboard component
 */
export default {
  components: {
    // Layout,
  },
  data() {
    return {
      title: "Resources",
      items: [
        {
          text: "Zawiyah ",
        },
        {
          text: "Resources",
          active: true,
        },
      ],
      resources: [],
      level: 2,
      showMenu: false
    };
  },
  computed: {
    userData() {
      return JSON.parse(localStorage.getItem('user_info'))
    },
  },
  methods: {
    async getResources() {
      let results = (await api.get('/resources')).data
      this.resources = results.sort((a, b) => (a.position > b.position) ? 1 : -1)
    }
  },
  mounted() {
    this.getResources()
  },
};
</script>

<template>
  <!-- <Layout> -->
    <body id="page-top">
      <!-- Navigation-->
      <nav
        class="navbar navbar-expand-lg navbar-light fixed-top shadow-sm"
        id="mainNav"
      >
        <div class="container px-5">
          <a class="navbar-brand fw-bold" href="#page-top">Zawiyah Foundation</a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
            @click="showMenu = !showMenu"
          >
            Menu
            <i class="bi-list"></i>
          </button>
          <div class="collapse navbar-collapse" id="navbarResponsive" :class="{'show': showMenu}">
            <ul class="navbar-nav ms-auto me-4 my-3 my-lg-0">
              <li class="nav-item">
                <a class="nav-link me-lg-3" href="/">Home</a>
              </li>
              <li class="nav-item">
                <a class="nav-link me-lg-3" href="/#/team">Team</a>
              </li>
              <li class="nav-item">
                <a class="nav-link me-lg-3" href="/#/wellness-club">Wellness Club</a>
              </li>
              <li class="nav-item">
                <a class="nav-link me-lg-3" href="/#/resources">Resources</a>
              </li>
            </ul>
            <a v-if="userData && userData.id && userData.role == 'user'" class="btn btn-primary rounded-pill px-3 mb-2 mb-lg-0" href="/#/dashboard">Dashboard</a>
            <a v-else-if="userData && userData.id && userData.role == 'admin'" class="btn btn-primary rounded-pill px-3 mb-2 mb-lg-0" href="/#/admin">Dashboard</a>
            <a v-else class="btn btn-primary rounded-pill px-3 mb-2 mb-lg-0" href="/#/login">Login</a>          </div>
        </div>
      </nav>
      <!-- Mashead header-->

      <!-- Quote/testimonial aside-->
      <aside class="text-center bg-gradient-primary-to-secondary pb-4">
        <div class="container px-5">
          <div class="row gx-5 justify-content-center">
            <div class="col-xl-8">
              <div class="h2 fs-1 text-white mb-4 pt-5 pb-0">
                Resources
              </div>
              <!-- <img
                src="assets/img/tnw-logo.svg"
                alt="..."
                style="height: 3rem"
              /> -->
            </div>
          </div>
        </div>
      </aside>
      <!-- App features section-->
      <div class="container mt-5">
  <div class="row mb-3">
    <div v-for="resource in resources" :key="resource.id" class="card col-4 mb-4 shadow-none border-0">
      <!-- {{resource}} -->
      <h5 class="">{{resource.title}}</h5>
      <iframe v-if="resource.type == 'pdf'"
          :src="`https://drive.google.com/viewerng/viewer?embedded=true&url=${resource.path}#toolbar=1&scrollbar=0`"
          frameBorder="0"
          scrolling="auto"
          height="200"
          width="100%"
      ></iframe>
      <iframe v-if="resource.type == 'video'"
      width="100%" height="200" :src="resource.path" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <!-- <div class="card col-4 mb-4 shadow-none border-0">
    <iframe width="100%" height="200" src="https://www.youtube.com/embed/YrhFvbqSb6A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <div class="card col-4 mb-4 shadow-none border-0">
    <iframe width="100%" height="200" src="https://www.youtube.com/embed/YrhFvbqSb6A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <div class="card col-4 mb-4 shadow-none border-0">
    <iframe width="100%" height="200" src="https://www.youtube.com/embed/YrhFvbqSb6A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div> -->
  </div>
   <div style="text-align: center" class="py-5">
            <a href="https://www.youtube.com/channel/UCv5PPF5zjtjbm_TXOFGIP3w" target="_blank" class="btn btn-lg btn-danger mb-2"><i class="mdi mdi-youtube mr-2"></i> Follow Us On Youtube </a>
          </div>
</div>
      <!-- Call to action section-->
      <!-- App badge section-->
      <section class="bg-gradient-primary-to-secondary" id="download">
        <div class="container px-5">
          <h2 class="text-center text-white font-alt mb-4">Get in Touch!</h2>
          <div>
            <h4 class="text-center text-white font-alt">+92 321 4609800</h4>
            <h4 class="text-center text-white font-alt">shafaqatali0033@gmail.com</h4>
          </div>
          <!-- <div
            class="
              d-flex
              flex-column flex-lg-row
              align-items-center
              justify-content-center
            "
          >
            <a class="me-lg-3 mb-4 mb-lg-0" href="#!"
              ><img
                class="app-badge"
                src="assets/img/google-play-badge.svg"
                alt="..."
            /></a>
            <a href="#!"
              ><img
                class="app-badge"
                src="assets/img/app-store-badge.svg"
                alt="..."
            /></a>
          </div> -->
        </div>
      </section>
      <!-- Footer-->
      <footer class="bg-black text-center py-5">
        <div class="container px-5">
          <div class="text-white-50 small">
            <div class="mb-2">
              &copy; Zawiyah Foundation Website 2022. All Rights Reserved.
            </div>
            <!-- <a href="#">Privacy</a>
            <span class="mx-1">&middot;</span>
            <a href="#">Terms</a>
            <span class="mx-1">&middot;</span>
            <a href="#">FAQ</a> -->
          </div>
        </div>
      </footer>
      <!-- Feedback Modal-->
      <div
        class="modal fade"
        id="feedbackModal"
        tabindex="-1"
        aria-labelledby="feedbackModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header bg-gradient-primary-to-secondary p-4">
              <h5
                class="modal-title font-alt text-white"
                id="feedbackModalLabel"
              >
                Send feedback
              </h5>
              <button
                class="btn-close btn-close-white"
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body border-0 p-4">
              <!-- * * * * * * * * * * * * * * *-->
              <!-- * * SB Forms Contact Form * *-->
              <!-- * * * * * * * * * * * * * * *-->
              <!-- This form is pre-integrated with SB Forms.-->
              <!-- To make this form functional, sign up at-->
              <!-- https://startbootstrap.com/solution/contact-forms-->
              <!-- to get an API token!-->
              <form id="contactForm" data-sb-form-api-token="API_TOKEN">
                <!-- Name input-->
                <div class="form-floating mb-3">
                  <input
                    class="form-control"
                    id="name"
                    type="text"
                    placeholder="Enter your name..."
                    data-sb-validations="required"
                  />
                  <label for="name">Full name</label>
                  <div
                    class="invalid-feedback"
                    data-sb-feedback="name:required"
                  >
                    A name is required.
                  </div>
                </div>
                <!-- Email address input-->
                <div class="form-floating mb-3">
                  <input
                    class="form-control"
                    id="email"
                    type="email"
                    placeholder="name@example.com"
                    data-sb-validations="required,email"
                  />
                  <label for="email">Email address</label>
                  <div
                    class="invalid-feedback"
                    data-sb-feedback="email:required"
                  >
                    An email is required.
                  </div>
                  <div class="invalid-feedback" data-sb-feedback="email:email">
                    Email is not valid.
                  </div>
                </div>
                <!-- Phone number input-->
                <div class="form-floating mb-3">
                  <input
                    class="form-control"
                    id="phone"
                    type="tel"
                    placeholder="(123) 456-7890"
                    data-sb-validations="required"
                  />
                  <label for="phone">Phone number</label>
                  <div
                    class="invalid-feedback"
                    data-sb-feedback="phone:required"
                  >
                    A phone number is required.
                  </div>
                </div>
                <!-- Message input-->
                <div class="form-floating mb-3">
                  <textarea
                    class="form-control"
                    id="message"
                    type="text"
                    placeholder="Enter your message here..."
                    style="height: 10rem"
                    data-sb-validations="required"
                  ></textarea>
                  <label for="message">Message</label>
                  <div
                    class="invalid-feedback"
                    data-sb-feedback="message:required"
                  >
                    A message is required.
                  </div>
                </div>
                <!-- Submit success message-->
                <!---->
                <!-- This is what your users will see when the form-->
                <!-- has successfully submitted-->
                <div class="d-none" id="submitSuccessMessage">
                  <div class="text-center mb-3">
                    <div class="fw-bolder">Form submission successful!</div>
                    To activate this form, sign up at
                    <br />
                    <a href="https://startbootstrap.com/solution/contact-forms"
                      >https://startbootstrap.com/solution/contact-forms</a
                    >
                  </div>
                </div>
                <!-- Submit error message-->
                <!---->
                <!-- This is what your users will see when there is-->
                <!-- an error submitting the form-->
                <div class="d-none" id="submitErrorMessage">
                  <div class="text-center text-danger mb-3">
                    Error sending message!
                  </div>
                </div>
                <!-- Submit Button-->
                <div class="d-grid">
                  <button
                    class="btn btn-primary rounded-pill btn-lg disabled"
                    id="submitButton"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- Bootstrap core JS-->
      <!-- <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"></script> -->
      <!-- Core theme JS-->
      <!-- <script src="js/scripts.js"></script> -->
      <!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *-->
      <!-- * *                               SB Forms JS                               * *-->
      <!-- * * Activate your form at https://startbootstrap.com/solution/contact-forms * *-->
      <!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *-->
      <!-- <script src="https://cdn.startbootstrap.com/sb-forms-latest.js"></script> -->
    </body>
  <!-- </Layout> -->
</template>
<style >
  @import './styles.css';
</style>